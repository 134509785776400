@font-face {
  font-family: 'HPSimplified_Rg';
  font-style: normal;
  font-weight: normal;
  src: url(./../fonts/8c8205b8fa7e7fbe666e25e50819bf26.ttf) format("truetype"); }

@font-face {
  font-family: 'Gotham Ultra';
  font-weight: normal;
  font-style: normal;
  src: url(./../fonts/4be8f35d796aaf649a2e231b09ccd4c1.otf) format("opentype"); }

@font-face {
  font-family: 'Gotham Bold';
  font-weight: bold;
  font-style: normal;
  src: url(./../fonts/19cbd08d9da163ba87d64997ae97988e.otf) format("opentype"); }

@font-face {
  font-family: 'Gotham_Regular';
  font-weight: normal;
  font-style: normal;
  src: url(./../fonts/ec261687c39b5046869a7b0e14937c78.otf) format("opentype"); }

/*////hello  */
