@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=80em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #ffffff;
  font-family: "hp-simplified-light", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5;
  color: #767676;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 3px; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 3px; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  -webkit-appearance: none;
          appearance: none;
  border: 0;
  border-radius: 3px;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "hp-simplified-light", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: #b5b4b7; }

h1 {
  font-size: 2.625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2 {
  font-size: 2.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 3rem; }
  h2 {
    font-size: 2.5rem; }
  h3 {
    font-size: 1.9375rem; }
  h4 {
    font-size: 1.5625rem; }
  h5 {
    font-size: 1.25rem; }
  h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #0096d6;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #0096d6;
    text-decoration: underline; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 80rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #b5b4b7;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #b5b4b7; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #767676; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #767676; }
  cite:before {
    content: "— "; }

abbr {
  border-bottom: 1px dotted #000000;
  color: #767676;
  cursor: help; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #b5b4b7;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: 300;
  color: #000000; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #000000;
  border-radius: 3px; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: 300;
  line-height: 1.4;
  color: #767676; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #767676;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.75em 2em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 3px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #0096d6;
  color: #ffffff; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #0080b6;
    color: #ffffff; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #5a5a5a;
    color: #ffffff; }
    .button.primary:hover, .button.primary:focus {
      background-color: #484848;
      color: #ffffff; }
  .button.secondary {
    background-color: #767676;
    color: #000000; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #000000; }
  .button.success {
    background-color: #17DA5E;
    color: #000000; }
    .button.success:hover, .button.success:focus {
      background-color: #12ae4b;
      color: #000000; }
  .button.warning {
    background-color: #ffae00;
    color: #000000; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #000000; }
  .button.alert {
    background-color: #cc4b37;
    color: #000000; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #000000; }
  .button.hollow {
    border: 1px solid #5a5a5a;
    color: #5a5a5a; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #595959;
      color: #595959; }
    .button.hollow.primary {
      border: 1px solid #5a5a5a;
      color: #5a5a5a; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #595959;
        color: #595959; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #757575;
        color: #757575; }
    .button.hollow.success {
      border: 1px solid #17DA5E;
      color: #17DA5E; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #17d85d;
        color: #17d85d; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #fcac00;
        color: #fcac00; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #cb4935;
        color: #cb4935; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #5a5a5a;
      color: #ffffff; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #5a5a5a;
        color: #ffffff; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #000000; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #17DA5E;
        color: #000000; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #000000; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #000000; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #ffffff transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 2em; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #b5b4b7;
  border-radius: 0;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 300;
  color: #000000;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  -webkit-appearance: none;
          appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #b5b4b7;
    background-color: #ffffff;
    box-shadow: 0 0 0 white;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #b5b4b7; }

input::placeholder,
textarea::placeholder {
  color: #b5b4b7; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  -webkit-appearance: none;
          appearance: none;
  border-radius: 3px; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.8;
  color: #000000; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #000000; }

.input-group {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  -ms-flex-align: stretch;
      align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #b5b4b7;
  background: #e6e6e6;
  color: #000000;
  text-align: center;
  white-space: nowrap;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  -ms-flex-align: center;
      align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  -ms-flex: 1 1 0px;
      flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #b5b4b7; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem;
    background: #ffffff; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  -webkit-appearance: none;
          appearance: none;
  border: 1px solid #b5b4b7;
  border-radius: 3px;
  background-color: #ffffff;
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  color: #000000;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28118, 118, 118%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #b5b4b7;
    background-color: #ffffff;
    box-shadow: 0 0 0 white;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #faedeb; }
  .is-invalid-input:not(:focus):-ms-input-placeholder {
    color: #cc4b37; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(0, 0, 0, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #b5b4b7;
  border-radius: 0;
  background-color: #ffffff;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column,
  .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 80rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal .reveal {
      right: auto;
      left: auto;
      margin: 0 auto; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 80rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 80rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 80rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

body {
  padding-bottom: 0;
  -ms-touch-action: pan-x pan-y;
      touch-action: pan-x pan-y; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #5a5a5a; }

.hidden {
  position: absolute;
  left: -10001px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  display: none;
  /* is this going o be a problem? fixes rtl HPC3-2175 -jh */ }

.banner {
  margin-bottom: 3.125rem;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
  -ms-flex-align: center;
      align-items: center; }

hr {
  max-width: 500rem;
  margin: 0;
  border-bottom: 1px solid #b9b8bb; }

.breadCrumbs {
  display: -ms-flexbox;
  display: flex;
  height: 2.75rem;
  -ms-flex-align: center;
      align-items: center;
  font-family: 'hp-simplified', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif; }

.input-group {
  margin: 0 auto 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem; }
  @media screen and (min-width: 40em) {
    .input-group {
      max-width: 37.5rem; } }
  @media screen and (min-width: 64em) {
    .input-group {
      max-width: 43.75rem; } }

textarea {
  padding: 0.5rem; }

input[type='search'] {
  border-radius: 0;
  -webkit-appearance: none !important; }

.input-group-field {
  padding: 1rem; }

.input-group-button {
  background-color: #b5b4b7;
  width: 4.375rem;
  padding: 0.75rem; }
  .input-group-button img {
    width: 1.875rem;
    margin: auto; }
  @media print, screen and (min-width: 40em) {
    .input-group-button {
      width: 6.25rem; } }
  .search .input-group-button {
    border-radius: 0; }

.button {
  font-family: 'hp-simplified', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-size: 1.125rem; }
  @media screen and (max-width: 63.9375em) {
    .button {
      border-radius: 0; } }
  .button:hover, .button:active, .button:focus {
    text-decoration: none; }

.button.hollow {
  color: #5a5a5a;
  border-color: #5a5a5a; }
  .button.hollow:hover {
    color: #0096d6;
    border-color: #0096d6; }

.button-inactive.hollow {
  color: #b5b4b7;
  border-color: #b5b4b7; }
  .button-inactive.hollow:hover, .button-inactive.hollow:active {
    color: #b5b4b7;
    border-color: #b5b4b7;
    cursor: default; }

.button--primary.hollow {
  border: solid 2px; }

.binaryOptions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 50%;
      flex: 1 1 50%;
  border-top: 1px solid #767676; }

.binaryOptions--button {
  width: 100%;
  min-height: 0.625rem;
  padding: 0.875rem 0.3125rem 0.875rem 0.875rem;
  font-size: 0.875rem;
  border-radius: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: start;
      justify-content: flex-start;
  color: #5a5a5a;
  cursor: pointer; }
  .binaryOptions--button:focus {
    outline: none; }
  .binaryOptions--button :last-child {
    border-left: 1px solid lightgray;
    border-bottom: 1px solid transparent; }
  .binaryOptions--button::before {
    content: url(/assets/img/common/icon-radio-button-off.svg);
    margin-right: 0.5rem; }

.binaryOptions--button-active {
  width: 100%;
  min-height: 0.625rem;
  padding: 0.875rem 0.3125rem 0.875rem 0.875rem;
  font-size: 0.875rem;
  border-radius: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: start;
      justify-content: flex-start;
  color: #5a5a5a;
  cursor: pointer;
  background-color: #f2f2f2; }
  .binaryOptions--button-active:focus {
    outline: none; }
  .binaryOptions--button-active :last-child {
    border-left: 1px solid lightgray;
    border-bottom: 1px solid transparent; }
  .binaryOptions--button-active::before {
    content: url(/assets/img/common/icon-radio-button-on.svg);
    margin-right: 0.5rem; }

.sectionHeader {
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin-bottom: 2.25rem;
  -ms-flex-align: center;
      align-items: center; }
  @media screen and (min-width: 40em) {
    .sectionHeader {
      margin-bottom: 3.75rem; } }
  @media screen and (min-width: 64em) {
    .sectionHeader {
      margin-bottom: 5rem; } }
  @media screen and (min-width: 40em) {
    .sectionHeader--withIcon {
      margin-left: -3rem;
      margin-right: 0; } }
  .sectionHeader--icon {
    width: 3rem;
    display: inline-block;
    margin-right: 0.5rem;
    /* rtl */
    margin-left: 0.5rem;
    /* rtl */ }
    .sectionHeader--icon img {
      width: 100%; }

.sectionHeader h2 {
  font-size: 2rem;
  margin-bottom: 0;
  line-height: 1;
  margin-right: 0.5rem;
  /* rtl */
  margin-left: 0.5rem;
  /* rtl */ }
  @media screen and (min-width: 40em) {
    .sectionHeader h2 {
      font-size: 2.625rem; } }
  @media screen and (min-width: 64em) {
    .sectionHeader h2 {
      font-size: 3.375rem; } }

.get {
  padding-bottom: 4.375rem; }

.appPromo {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
  padding-bottom: 5.5rem; }

.appPromo--pitch {
  width: 100%;
  max-width: 52rem;
  -ms-flex-pack: center;
      justify-content: center;
  margin: 0 auto 1.875rem;
  font-size: 1.125rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .appPromo--pitch {
      font-size: 1.25rem;
      text-align: center; } }

.app-store-badges {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  width: 100%;
  margin: 0 auto;
  max-width: 50rem; }
  @media print, screen and (min-width: 40em) {
    .app-store-badges {
      -ms-flex-pack: justify;
          justify-content: space-between;
      padding-bottom: 1rem; } }
  .app-store-badges img {
    width: 100%;
    height: 100%; }
  .app-store-badges a {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 33%;
        flex: 1 1 33%;
    max-width: 8.75rem;
    max-height: 2.75rem; }
    @media print, screen and (min-width: 40em) {
      .app-store-badges a {
        max-width: 10rem;
        max-height: 3rem; } }
    @media print, screen and (min-width: 64em) {
      .app-store-badges a {
        max-width: 11.25rem;
        max-height: 3.5rem; } }

.ink {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
  padding-bottom: 5.5rem; }

.cartridges {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 50rem;
  width: 100%;
  margin: auto;
  padding-top: 0;
  padding-bottom: 1rem; }
  @media print, screen and (min-width: 40em) {
    .cartridges {
      -ms-flex-pack: left;
          justify-content: left;
      border-bottom: 1px solid #b9b8bb;
      margin-bottom: 1.25rem; } }

.cartridge {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: start;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex: 0 1 21.875rem;
      flex: 0 1 21.875rem;
  padding-top: 0;
  padding-bottom: 1rem;
  font-size: 1.125rem; }
  .cartridge p {
    margin-bottom: 0;
    padding-top: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  @media print, screen and (min-width: 40em) {
    .cartridge {
      -ms-flex: 0 1 50%;
          flex: 0 1 50%; } }

.cartridge-number {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 5rem;
  height: auto;
  margin-right: 0; }
  .cartridge-number img {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    width: 100%;
    height: 100%; }

.cartridge-description {
  -ms-flex: 0 1 auto;
      flex: 0 1 auto; }

.purchaseOptions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  font-size: 1.125rem;
  -ms-flex-align: center;
      align-items: center; }
  @media print, screen and (min-width: 40em) {
    .purchaseOptions {
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-pack: distribute;
          justify-content: space-around; } }
  .purchaseOptions > * + * {
    margin-top: 1.25rem; }
    @media print, screen and (min-width: 40em) {
      .purchaseOptions > * + * {
        margin-top: 0;
        /* // margin-left: rem-calc(200); /* rtl */ } }

.purchaseOptions--option {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  text-align: center; }
  .purchaseOptions--option .button {
    min-width: 12.5rem;
    margin: 0 auto; }
  .purchaseOptions--option:only-child .button {
    min-width: 20rem; }

.final-cta {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
  padding-bottom: 5.5rem;
  -ms-flex-align: center;
      align-items: center; }
  .final-cta p {
    text-align: center;
    margin-bottom: 2.5rem;
    font-size: 1.25rem; }
    @media print, screen and (min-width: 40em) {
      .final-cta p {
        font-size: 1.5rem; } }
  .final-cta .button {
    min-width: 12.5rem; }

.setting--meta {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
  min-height: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem; }
  @media print, screen and (min-width: 40em) {
    .setting--meta {
      padding-left: 1.875rem; } }

.setting--form {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  flex: 1 1 auto; }
  @media print, screen and (min-width: 40em) {
    .setting--form {
      padding-left: 1.875rem;
      padding-right: 12.5rem; } }
  @media print, screen and (min-width: 64em) {
    .setting--form {
      padding-right: 37.5rem; } }

.subheader {
  font-weight: bold; }

.setting--input {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-bottom: 1rem; }
  .setting--input [type='checkbox'] {
    margin: 0.375rem 0.375rem 0 0;
    width: 1.5rem; }

.settingButtons--buttonPair {
  display: -ms-flexbox;
  display: flex; }
  .settingButtons--buttonPair a {
    width: 50%;
    font-size: 1rem;
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 1.25rem; }
  .settingButtons--buttonPair * + * {
    margin-left: 1.25rem; }
  .settingButtons--buttonPair a:last-child {
    border: solid 2px; }

.settingButtons--buttonPair {
  padding-top: 0.4375rem; }

a.settings--inputLink {
  color: #5a5a5a; }
  a.settings--inputLink-inactive {
    color: #b5b4b7; }
  a.settings--inputLink:hover, a.settings--inputLink:active {
    color: #5a5a5a; }
  a.settings--inputLink:hover, a.settings--inputLink:active, a.settings--inputLink:focus {
    text-decoration: none; }

.gnb--bg {
  border-bottom: 1px solid #b9b8bb;
  z-index: 10; }

#background {
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  opacity: 1;
  z-index: 5;
  transition: height .5s;
  background-color: #F2F2f2; }
  @media print, screen and (min-width: 40em) {
    #background {
      background-color: transparent; } }

.gnb {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
  -ms-flex-pack: justify;
      justify-content: space-between;
  color: #5a5a5a;
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
  -ms-flex-align: center;
      align-items: center; }
  .gnb a {
    font-family: 'hp-simplified', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    color: #5a5a5a;
    padding: 0.625rem 0.75rem; }
    .gnb a:hover, .gnb a:active, .gnb a:focus {
      color: #0096d6;
      text-decoration: none; }
  .gnb a#brand {
    display: -ms-flexbox;
    display: flex;
    line-height: 1;
    padding: 0.6875rem 0 0.6875rem 0.625rem;
    margin-bottom: 0;
    text-decoration: none;
    font-size: 1.5rem;
    -ms-flex-align: center;
        align-items: center; }
    @media print, screen and (min-width: 40em) {
      .gnb a#brand {
        padding-left: 1.25rem; } }
    @media print, screen and (min-width: 40em) {
      .gnb a#brand {
        font-size: 1.875rem; } }

.logo {
  width: 3.125rem;
  margin-right: 0.75rem; }
  .logo img {
    width: 100%; }

nav.menuPrimary {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  z-index: 50;
  top: 4.5625rem;
  left: 0;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  width: 100%;
  height: 100%; }
  @media print, screen and (min-width: 40em) {
    nav.menuPrimary {
      -ms-flex-direction: row;
          flex-direction: row;
      top: inherit;
      left: inherit;
      position: inherit;
      margin-top: 0.5rem; } }
  @media print, screen and (min-width: 40em) {
    nav.menuPrimary {
      position: relative;
      top: inherit;
      -ms-flex-direction: row;
          flex-direction: row;
      background-color: transparent;
      left: inherit;
      width: auto; } }

.menuLink.menuLink--search {
  padding: 0.75rem 0.625rem 0.625rem;
  border-bottom: none;
  line-height: 1;
  display: -ms-flexbox;
  display: flex; }
  .menuPrimary .menuLink.menuLink--search {
    display: none; }
    @media print, screen and (min-width: 40em) {
      .menuPrimary .menuLink.menuLink--search {
        display: inline-block; } }

.menuLink--searchIcon {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: middle; }
  @media print, screen and (min-width: 40em) {
    .menuLink--searchIcon {
      width: 1.25rem;
      height: 1.25rem; } }
  .menuLink--searchIcon .search--icon {
    width: 100%; }

.menuLink {
  display: inline-block;
  border-bottom: solid 1px #E5E8E8; }
  .localeMenu .menuLink {
    width: 100%;
    padding: 0.5rem 0.75rem 0.5rem 1.75rem;
    border-bottom: solid 1px #DBDBDB; }
    @media print, screen and (min-width: 40em) {
      .localeMenu .menuLink {
        border-bottom: none; } }
  @media print, screen and (min-width: 40em) {
    .menuLink {
      margin-left: 0;
      padding-left: 0;
      border-bottom: none; } }
  .localeMenu li:first-child .menuLink {
    padding-top: 0.75rem; }
    @media print, screen and (min-width: 40em) {
      .localeMenu li:first-child .menuLink {
        padding-top: 0.625rem; } }
  .menuLink svg.search--icon {
    fill: none;
    stroke: #5A5A5A;
    stroke-width: 2;
    stroke-miterlimit: 10; }
  .menuLink:hover, .menuLink:active, .menuLink.active {
    text-decoration: none; }
    .menuLink:hover svg.search--icon, .menuLink:active svg.search--icon, .menuLink.active svg.search--icon {
      stroke: #007DBA; }
  .menuLink:visited {
    text-decoration: none; }

@media print, screen and (min-width: 40em) {
  a.menuLink {
    padding-left: 0.75rem; }
    a.menuLink.menuLink--settings {
      padding-left: 0.5rem; } }

a.menuLink-deactivated {
  font-family: "hp-simplified-light", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }
  a.menuLink-deactivated:hover, a.menuLink-deactivated:active {
    color: #5a5a5a; }

.account-name {
  max-width: 0;
  overflow: hidden;
  vertical-align: top; }

.menuLink--icon {
  width: 1.5rem;
  margin-left: .4rem;
  display: inline-block;
  text-align: center; }
  @media print, screen and (min-width: 40em) {
    .menuLink--icon {
      margin-left: 0; } }
  .menuLink--localeSelector .menuLink--icon, .localeMenu .menuLink--icon {
    max-height: 1.5rem; }
  .menuLink--icon img {
    max-height: 1.625rem; }
  .menuPrimary .menuLink--icon {
    max-height: inherit; }
    .menuPrimary .menuLink--icon img {
      max-height: inherit; }
  @media print, screen and (min-width: 40em) {
    .menuLink--icon {
      display: none; }
      .menuLink--localeSelector .menuLink--icon, .localeMenu .menuLink--icon {
        width: 1.625rem;
        max-height: 0.875rem;
        display: inline-block; }
        .menuLink--localeSelector .menuLink--icon img, .localeMenu .menuLink--icon img {
          max-height: 1.25rem; }
      .menuLink--settings .menuLink--icon {
        width: 1.625rem;
        height: 1.875rem;
        display: inline-block; } }

.menuLink--text {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  display: inline-block; }
  @media print, screen and (min-width: 40em) {
    .menuLink--text {
      padding-left: initial; } }

@media print, screen and (min-width: 40em) {
  .menuLink--signIn {
    -ms-flex-order: 10;
        order: 10; } }

.menuLink--settings {
  display: inline-block; }
  @media print, screen and (min-width: 40em) {
    .menuLink--settings {
      -ms-flex-order: 9;
          order: 9; }
      .menuLink--settings:hover svg {
        stroke: #007DBA; } }
  @media print, screen and (min-width: 40em) {
    .menuLink--settings .menuLink--text {
      padding-left: 0.3125rem; } }

.menuLink--settingsInline {
  vertical-align: middle;
  height: 100%;
  width: 100%;
  padding-bottom: 4px; }

a.menuLink--localeSelector {
  padding-bottom: 1.125rem; }
  a.menuLink--localeSelector .menuLink--text {
    display: inline-block; }
    @media print, screen and (min-width: 40em) {
      a.menuLink--localeSelector .menuLink--text {
        display: none; } }
    @media print, screen and (min-width: 64em) {
      a.menuLink--localeSelector .menuLink--text {
        display: inline-block;
        padding-left: 0.75rem; } }

.localeMenu--bg {
  visibility: hidden;
  opacity: 0;
  display: none;
  display: none;
  -ms-flex-order: 11;
      order: 11;
  width: 100%;
  max-width: 1280px;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: -2; }
  .localeMenu--bg-there {
    display: block; }
  @media print, screen and (min-width: 40em) {
    .localeMenu--bg {
      position: absolute; } }

.localeMenu--container {
  width: 100%;
  height: 100%;
  position: relative; }
  @media print, screen and (min-width: 40em) {
    .localeMenu--container {
      width: initial; } }

.menuLink--localeSelector .menuLink--icon img, .menuLink--localeDropdown .menuLink--icon img {
  border: .25px #b9b8bb solid; }

.localeMenu {
  z-index: 100;
  right: 0;
  top: 0;
  background-color: #EAEAEA;
  max-width: 40rem;
  border-top: solid 1px #b9b8bb; }
  @media print, screen and (min-width: 40em) {
    .localeMenu {
      top: 3.625rem;
      right: 0.625rem;
      background-color: white;
      border: solid #b9b8bb 1px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      column-count: 2;
      padding: 0.625rem 0; } }
  .localeMenu li {
    list-style-type: none;
    background-color: transparent; }
    @media print, screen and (min-width: 40em) {
      .localeMenu li {
        min-width: 19rem; } }
    .localeMenu li.current a:hover {
      color: #5a5a5a; }
    .localeMenu li .menuLink--text {
      display: inline;
      padding-left: 1.625rem;
      padding-right: 1.625rem; }

@media screen and (max-width: 39.9375em) {
  #locale-menu .localeMenu:after {
    bottom: 100%;
    right: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #EAEAEA;
    border-width: 10px;
    margin-right: -10px; } }
  @media screen and (max-width: 39.9375em) and (min-width: 40em) {
    #locale-menu .localeMenu:after {
      right: 4.1875rem; } }
  @media screen and (max-width: 39.9375em) and (min-width: 64em) {
    #locale-menu .localeMenu:after {
      right: 11.25rem; } }
  @media screen and (max-width: 39.9375em) and (min-width: 40em) {
    #locale-menu .localeMenu:after {
      border-bottom-color: #ffffff; } }

@media screen and (max-width: 39.9375em) {
  #locale-menu .localeMenu:before {
    bottom: 100%;
    right: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(185, 184, 187, 0);
    border-bottom-color: #b9b8bb;
    border-width: 11px;
    margin-right: -11px; } }
  @media screen and (max-width: 39.9375em) and (min-width: 40em) {
    #locale-menu .localeMenu:before {
      right: 4.1875rem; } }
  @media screen and (max-width: 39.9375em) and (min-width: 64em) {
    #locale-menu .localeMenu:before {
      right: 11.25rem; } }

nav.menuMobile {
  display: -ms-flexbox;
  display: flex; }
  @media print, screen and (min-width: 40em) {
    nav.menuMobile {
      display: none; } }
  nav.menuMobile .menuPrimary .menuLink {
    padding-left: 1.75rem; }

.search--dropDownContainer {
  visibility: hidden;
  opacity: 0;
  display: none;
  background-color: white;
  z-index: 100;
  display: block;
  position: absolute;
  min-height: 6.25rem;
  top: 4.4375rem;
  left: 0;
  right: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }

#mobile-icon-button {
  display: -ms-flexbox;
  display: flex; }
  #mobile-icon-button #mobile-icon {
    margin: auto 0; }
  #mobile-icon-button:hover #mobile-icon-shapes, #mobile-icon-button:active #mobile-icon-shapes, #mobile-icon-button:focus #mobile-icon-shapes {
    fill: #0096d6; }

.search--dropDown .input-group {
  margin: 1.5rem auto; }
  @media print, screen and (min-width: 40em) {
    .search--dropDown .input-group {
      margin: 2.5rem auto; } }

.settingsDropDown--container {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
  position: relative; }

.settingsDropDown--background {
  visibility: hidden;
  opacity: 0;
  display: none;
  border: solid #b9b8bb 1px;
  background-color: white;
  z-index: 100;
  display: block;
  position: absolute;
  right: 1rem;
  top: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }

.settingsDropDown {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin: 0; }

.settingsDropDown--link {
  white-space: nowrap;
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  padding: 0.5rem 1.25rem;
  width: 100%;
  height: 100%;
  color: #5a5a5a; }
  .settingsDropDown--link:hover {
    text-decoration: none; }

.settingDropDown--label {
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  width: 100%;
  height: 100%;
  color: #5a5a5a; }
  .settingDropDown--label:hover {
    text-decoration: none; }

.modalWindow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  position: relative;
  padding-bottom: 0; }
  .modalWindow h1, .modalWindow h2, .modalWindow h3, .modalWindow h4 {
    font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }
  .modalWindow h3 {
    font-size: 1.875rem; }
  .modalWindow--buttonPair {
    display: -ms-flexbox;
    display: flex; }
    .modalWindow--buttonPair a {
      width: 50%;
      font-size: 1.125rem;
      padding-left: 2em;
      padding-right: 2em;
      margin-bottom: 1.25rem; }
    .modalWindow--buttonPair * + * {
      margin-left: 1.25rem; }
    .modalWindow--buttonPair a:last-child {
      border: solid 2px; }
  .modalWindow--header {
    display: -ms-flexbox;
    display: flex; }

a.modalWindow--close {
  font-size: 2.5rem;
  line-height: .5;
  position: absolute;
  top: 1.25rem;
  right: 1.25rem; }
  a.modalWindow--close:hover {
    text-decoration: none;
    color: #5a5a5a; }

@keyframes textLoader {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.42; }
  100% {
    opacity: 1; } }

@keyframes pageLoader {
  0% {
    opacity: 0; }
  1% {
    opacity: 1; }
  30% {
    opacity: 1; }
  80% {
    opacity: 0.5; }
  100% {
    opacity: 0; } }

.loaderElement-right {
  margin-left: auto; }

svg.loaderElement--svg {
  width: auto;
  line-height: 1; }
  svg.loaderElement--svg circle {
    fill: #0096d6;
    opacity: 0;
    animation: textLoader 0.8s ease infinite; }
    svg.loaderElement--svg circle:nth-child(2) {
      animation-delay: 0.2s; }
    svg.loaderElement--svg circle:nth-child(3) {
      animation-delay: 0.4s; }
    svg.loaderElement--svg circle:nth-child(4) {
      animation-delay: 0.6s; }
    svg.loaderElement--svg circle:nth-child(5) {
      animation-delay: 0.8s; }

.loaderBlock {
  margin: auto;
  text-align: center; }

svg.loaderBlock--svg {
  width: auto;
  line-height: 1;
  margin-bottom: 0.625rem; }
  svg.loaderBlock--svg circle {
    animation: pageLoader 3.2s ease infinite;
    fill: #0096d6;
    opacity: 0; }
    svg.loaderBlock--svg circle:nth-child(2) {
      animation-delay: 0.2s; }
    svg.loaderBlock--svg circle:nth-child(3) {
      animation-delay: 0.4s; }
    svg.loaderBlock--svg circle:nth-child(4) {
      animation-delay: 0.6s; }
    svg.loaderBlock--svg circle:nth-child(5) {
      animation-delay: 0.8s; }
    svg.loaderBlock--svg circle:nth-child(6) {
      animation-delay: 1s; }
    svg.loaderBlock--svg circle:nth-child(7) {
      animation-delay: 1.2s; }
    svg.loaderBlock--svg circle:nth-child(8) {
      animation-delay: 1.4s; }
    svg.loaderBlock--svg circle:nth-child(9) {
      animation-delay: 1.6s; }
    svg.loaderBlock--svg circle:nth-child(10) {
      animation-delay: 1.8s; }
    svg.loaderBlock--svg circle:nth-child(11) {
      animation-delay: 2s; }
    svg.loaderBlock--svg circle:nth-child(12) {
      animation-delay: 2.2s; }
    svg.loaderBlock--svg circle:nth-child(13) {
      animation-delay: 2.4s; }
    svg.loaderBlock--svg circle:nth-child(14) {
      animation-delay: 2.6s; }
    svg.loaderBlock--svg circle:nth-child(15) {
      animation-delay: 2.8s; }
    svg.loaderBlock--svg circle:nth-child(16) {
      animation-delay: 3s; }

.pageContent .pageContent-loading {
  text-align: center;
  margin: 10em 0;
  display: none; }

.pageContent .pageContent {
  display: block;
  height: 100%; }

.pageContent.loading .pageContent-loading {
  display: block; }

.pageContent.loading .pageContent {
  display: none; }

.contentLoading {
  text-align: center;
  margin: 10em 0; }

#mobile-menu > button {
  margin-right: 315px; }

.footnote--section {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.footnote {
  font-size: 0.875rem; }
  .footnote sup {
    vertical-align: super;
    font-size: smaller; }

.footer--bg {
  z-index: 900;
  background-color: #5a5a5a;
  width: 100%;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto; }

footer#footer {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial; }
  footer#footer ul {
    margin-bottom: 0;
    margin-left: 0;
    -ms-flex: 0 1 auto;
        flex: 0 1 auto;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 0.3125rem 0;
    color: white;
    list-style-type: none; }
    footer#footer ul li {
      display: inline-block; }
      footer#footer ul li::after {
        content: "|";
        color: white; }
      footer#footer ul li:last-child::after {
        content: ""; }
  footer#footer a, footer#footer .footer--notALink {
    font-size: 0.875rem;
    color: white;
    padding: 0 0.625rem 0 0.6875rem;
    display: inline-block; }
    @media print, screen and (min-width: 40em) {
      footer#footer a, footer#footer .footer--notALink {
        font-size: 1rem;
        padding: 0.3125rem 0.625rem 0.3125rem 0.6875rem; } }

html, #view, #view > div:not(.revised) {
  height: 100%;
  position: relative; }

#view > div {
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -ms-flex-direction: column;
      flex-direction: column; }

.main--container {
  -ms-flex: 1 0 auto;
      flex: 1 0 auto; }

.supportSection {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
  padding-bottom: 5.5rem;
  display: flex;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center; }
  .supportSection > * {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
    @media screen and (min-width: 40em) {
      .supportSection > * {
        margin-left: 1.875rem;
        margin-right: 1.875rem; } }

.supportSection--supportPageLink {
  margin: auto;
  text-align: center; }

.supportSection--helpLinks {
  list-style-type: none;
  font-size: 1.375rem;
  line-height: 1.6;
  text-align: center; }
  .supportSection--helpLinks a {
    color: #5a5a5a; }

section.shared {
  position: relative;
  text-align: center;
  margin-bottom: 2.25rem; }
  @media screen and (min-width: 40em) {
    section.shared {
      margin-bottom: 3.625rem; } }
  @media screen and (min-width: 64em) {
    section.shared {
      margin-bottom: 5rem; } }
  section.shared picture {
    margin: auto; }

.shared-message-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  width: 100%;
  height: 100%;
  top: 0; }
  @media screen and (min-width: 80em) {
    .shared-message-container {
      -ms-flex-pack: end;
          justify-content: flex-end;
      position: absolute; } }
  @media screen and (min-width: 80em) {
    .shared-message-container {
      width: 80rem;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0; } }

.shared-message {
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 90%;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }
  .shared-message * {
    font-family: "hp-simplified-light", sans-serif;
    text-align: center;
    color: black; }
    @media screen and (min-width: 80em) {
      .shared-message * {
        text-align: left;
        color: white; } }
  @media screen and (min-width: 80em) {
    .shared-message {
      width: 40%;
      -ms-flex-pack: center;
          justify-content: center; } }
  .shared-message .button {
    color: black;
    border-color: black; }
    @media screen and (min-width: 80em) {
      .shared-message .button {
        color: white !important;
        border-color: white !important; } }

.shared-message-content {
  margin: 0 1.25rem;
  color: white; }
  @media screen and (min-width: 40em) {
    .shared-message-content {
      margin: 0 1.875rem; } }
  .shared-message-content h2 {
    line-height: 1.25; }
  @media screen and (min-width: 40em) {
    .shared-message-content h2 {
      font-size: 3.375rem;
      line-height: 1.185; } }
  .shared-message-content p {
    font-size: 1.25rem;
    margin: 1rem 0 2rem; }
  @media screen and (min-width: 80em) {
    .shared-message-content p {
      line-height: 1;
      font-size: 1.75rem; } }

.hero {
  position: relative; }

.hero--wrapper {
  margin-bottom: 2rem; }
  @media screen and (min-width: 40em) {
    .hero--wrapper {
      margin-bottom: 4rem; } }

.hero--image {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .hero--image img {
    margin: 0 auto;
    width: 100%; }

.hero--content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  -ms-flex-pack: start;
      justify-content: flex-start; }

.hero--text {
  width: 100%;
  margin-top: 1.875rem; }
  @media screen and (min-width: 40em) {
    .hero--text {
      max-width: 80rem;
      margin-right: auto;
      margin-left: auto; } }
  @media screen and (min-width: 64em) {
    .hero--text {
      margin-top: 5rem; } }

.hero--headline {
  font-size: 2.25rem;
  color: black;
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  margin-left: 1.25rem;
  margin-right: 1.25rem; }
  @media screen and (min-width: 40em) {
    .hero--headline {
      margin-left: 1.875rem;
      margin-right: 1.875rem;
      font-size: 2.625rem; } }
  @media screen and (min-width: 64em) {
    .hero--headline {
      font-size: 3rem; } }

.hero--details {
  display: none; }
  @media screen and (min-width: 40em) {
    .hero--details {
      font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      display: block;
      margin-left: 1.875rem;
      width: 66%; } }
  @media screen and (min-width: 64em) {
    .hero--details {
      width: 50%; } }
  .hero--details p {
    font-size: 1.25rem;
    color: black; }
    @media screen and (min-width: 40em) {
      .hero--details p {
        font-size: 1.5rem; } }

@media screen and (min-width: 80em) {
  .hero--buttonContainer {
    width: 80rem;
    margin-left: auto;
    margin-right: auto; } }

.hero--buttonContainer .button.hollow {
  color: black !important;
  border: 3px solid black !important;
  border-radius: 0;
  font-size: 1.3125rem;
  margin-left: 1.25rem;
  margin-top: 3.125rem;
  background-color: rgba(255, 255, 255, 0.6); }
  .hero--buttonContainer .button.hollow:hover, .hero--buttonContainer .button.hollow:active {
    background-color: rgba(255, 255, 255, 0.9); }
  @media screen and (min-width: 40em) {
    .hero--buttonContainer .button.hollow {
      margin-left: 10rem;
      font-size: 1.25rem; } }
  @media screen and (min-width: 64em) {
    .hero--buttonContainer .button.hollow {
      margin-top: 10rem; } }

section.featureSection {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 1.875rem; }
  section.featureSection > * {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    color: #5A5A5A; }
    @media screen and (min-width: 40em) {
      section.featureSection > * {
        margin-left: 1.875rem;
        margin-right: 1.875rem; } }
  section.featureSection .sectionHeader {
    margin-bottom: 2rem; }
    @media screen and (min-width: 40em) {
      section.featureSection .sectionHeader {
        margin-bottom: 3.75rem; } }
  section.featureSection .cta {
    text-align: center;
    margin: 3.875rem 0 2rem; }
    @media screen and (min-width: 40em) {
      section.featureSection .cta {
        margin-top: 0;
        margin-bottom: 3rem; } }
    section.featureSection .cta .button {
      margin: 0; }
  @media screen and (min-width: 40em) {
    section.featureSection {
      padding-bottom: 3.75rem; } }

.feature {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin-bottom: 1.875rem; }
  @media screen and (min-width: 40em) {
    .feature {
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-pack: justify;
          justify-content: space-between;
      margin-bottom: 3.125rem; } }
  @media screen and (min-width: 64em) {
    .feature {
      margin-bottom: 6.25rem; } }
  .feature .accordionTitle {
    border-bottom: 1px solid #DDD;
    margin-bottom: 0; }

.feature--details {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  font-size: 1rem; }
  .feature--details .accordionTitle {
    padding-left: 0; }
    .feature--details .accordionTitle .accordionTitle--icon {
      line-height: 1.25; }

.feature--detailsLink {
  font-size: 0.875rem;
  padding: 0.375rem 0.5rem;
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  border: 1px solid #5a5a5a;
  color: #5a5a5a;
  margin-left: auto; }
  .feature--detailsLink:hover, .feature--detailsLink:active, .feature--detailsLink:focus {
    text-decoration: none;
    border: 1px solid #0096d6;
    color: #0096d6; }

.feature--details-active.accordion, .feature--details-active.accordion:last-child {
  border: none; }

.feature--details-active .accordionTitle {
  margin-bottom: 1rem;
  padding-left: 0; }

.feature--icon {
  -ms-flex: 0 0 2.625rem;
      flex: 0 0 2.625rem; }
  @media screen and (min-width: 40em) {
    .feature--icon {
      -ms-flex: 0 0 3.75rem;
          flex: 0 0 3.75rem; } }

.feature--text {
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 2rem;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media screen and (min-width: 40em) {
    .feature--text {
      -ms-flex: 0 1 40%;
          flex: 0 1 40%;
      -ms-flex-order: 1;
          order: 1;
      -ms-flex-pack: justify;
          justify-content: space-between;
      padding-bottom: 0; } }

@media screen and (min-width: 40em) {
  .feature:nth-child(odd) .feature--text {
    -ms-flex-order: 2;
        order: 2; } }

.feature--heading {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  width: 100%;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 1.25rem; }
  .feature--heading h3 {
    margin-bottom: 0;
    padding-left: 0.625rem;
    font-size: 1.875rem; }

.feature--description {
  font-size: 1.25rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin-bottom: auto; }
  .feature--description-active {
    margin-bottom: rem; }

.feature--more {
  text-align: center; }
  .feature--more a {
    font-size: 1.125rem; }

.feature--graphic {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  position: relative; }
  @media screen and (min-width: 40em) {
    .feature--graphic {
      -ms-flex-order: 2;
          order: 2;
      margin-right: -1.875rem;
      margin-left: 1.25rem;
      -ms-flex: 0 1 60%;
          flex: 0 1 60%; } }
  .feature--graphic img {
    width: 100%; }
  .feature--graphic .feature--info {
    position: absolute;
    right: 1.25rem;
    bottom: 1.25rem;
    margin-bottom: 0; }

@media screen and (min-width: 40em) {
  .feature:nth-child(odd) .feature--graphic {
    -ms-flex-order: 1;
        order: 1;
    margin-right: 1.25rem;
    margin-left: -1.875rem;
    -ms-flex: 0 1 60%;
        flex: 0 1 60%; } }

.internalPromo {
  display: block;
  margin-bottom: 5rem; }

.internalPromo-img {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 3.125rem; }
  .internalPromo-img img {
    margin: 0 auto;
    width: 100%; }
  @media print, screen and (min-width: 64em) {
    .internalPromo-img {
      margin-bottom: 5rem; } }

.internalPromo--container {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  -ms-flex-align: center;
      align-items: center; }
  .internalPromo--container .cta {
    text-align: center;
    margin: 0; }
    .internalPromo--container .cta .button {
      margin: 0; }

.internalPromo--message {
  margin-bottom: 3rem;
  max-width: 43.75rem; }
  .internalPromo--message p {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .internalPromo--message {
      margin-top: -0.625rem;
      margin-bottom: 5.5rem;
      font-size: 1.25rem; } }

.accordion .accordionTitle--icon {
  -ms-flex: 0 0 2rem;
      flex: 0 0 2rem; }
  .accordion .accordionTitle--icon img {
    transform: rotate(0);
    transition: transform 0.5s; }

.accordion.accordion-active .accordionTitle--icon img {
  transform: rotate(135deg); }

.accordion .accordionContent {
  max-height: 0;
  overflow: hidden; }

.accordionContentInk, .accordionContentCarePack {
  background-color: #e6e6e6;
  border-top: 1px solid #DDD;
  padding: 1.25rem; }
  @media print, screen and (min-width: 64em) {
    .accordionContentInk, .accordionContentCarePack {
      background-color: transparent; } }
  .accordionContentInk p, .accordionContentCarePack p {
    color: black; }
  .accordionContentInk--button, .accordionContentCarePack--button {
    width: 100%;
    margin-bottom: 0; }
  .accordionContentInk--tou p a, .accordionContentCarePack--tou p a {
    font-size: 0.875rem; }

.accordionContentInk--button.hollow:not(.button-inactive),
.promoSection--button.hollow:not(.button-inactive),
.ePrintAccessSetting--button.hollow:not(.button-inactive),
.ePrintAddressChange--buttonPair .button.hollow:not(.button-inactive) {
  color: #0096d6;
  border-color: #0096d6; }
  .accordionContentInk--button.hollow:not(.button-inactive):hover,
  .promoSection--button.hollow:not(.button-inactive):hover,
  .ePrintAccessSetting--button.hollow:not(.button-inactive):hover,
  .ePrintAddressChange--buttonPair .button.hollow:not(.button-inactive):hover {
    color: #5a5a5a;
    border-color: #5a5a5a; }

.eprintAccessSetting-loader {
  margin-left: 1.15rem; }

.feedbackMessage--error {
  border-radius: 5px;
  padding: 0.25rem 0.625rem;
  margin-bottom: 1.25rem;
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  border: 2px solid  #D67300;
  color: #D67300;
  display: none; }

.feedbackMessage--error-visible {
  border-radius: 5px;
  padding: 0.25rem 0.625rem;
  margin-bottom: 1.25rem;
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  border: 2px solid  #D67300;
  color: #D67300;
  display: block; }

.feedbackMessage--success {
  border-radius: 5px;
  padding: 0.25rem 0.625rem;
  margin-bottom: 1.25rem;
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  border: 2px solid #0084BC;
  color: #0084BC;
  display: none; }

.feedbackMessage--success-visible {
  border-radius: 5px;
  padding: 0.25rem 0.625rem;
  margin-bottom: 1.25rem;
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  border: 2px solid #0084BC;
  color: #0084BC;
  display: block; }

@media print, screen and (min-width: 40em) {
  .myPrinterSection--socialWidget {
    margin-bottom: 3.75rem; } }

@media print, screen and (min-width: 64em) {
  .myPrinterSection--socialWidget {
    margin-bottom: 0; } }

.myPrinters {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
  margin-bottom: 1.875rem;
  padding-left: 0;
  padding-right: 0; }
  @media print, screen and (min-width: 40em) {
    .myPrinters {
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      margin-bottom: 3.75rem; } }

.myPrinters--meta {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
  min-height: 1.25rem;
  padding-left: 1.25rem; }
  @media print, screen and (min-width: 40em) {
    .myPrinters--meta {
      padding-left: 1.875rem; } }

.myPrinter--ePrintAddress {
  margin: 0 auto 0.625rem;
  text-align: center;
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  line-height: 1.25; }

.myPrinter--visualSummary {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }

.myPrinterImage {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto 0.625rem;
  min-height: 5rem; }
  .myPrinter--visualSummary .myPrinterImage {
    margin: 0; }
  .myPrinterImage-unRegistered {
    margin: 1.25rem auto;
    max-width: 10rem; }

.myPrinterTitle {
  margin: 0 auto 0.625rem;
  text-align: center;
  font-size: 1.5rem; }
  .myPrinterTitle--status {
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 1rem; }

.myPrinterTitle-cardList {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; }

.myPrinterTitle-cardView {
  margin: 0 auto 1.25rem; }

.printerStatus {
  color: green; }

.settingSummary {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-bottom: 0.3125rem; }
  @media print, screen and (min-width: 40em) {
    .settingSummary {
      margin-bottom: 0.625rem; } }
  .settingSummary:last-child {
    margin-bottom: 1.25rem; }

.settingKey {
  font-weight: bold; }

.myPrinterCardView {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 70vw;
  max-width: 23.75rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 1.25rem auto 2.5rem;
  padding: 1.25rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
  @media screen and (min-width: 40em) {
    .myPrinterCardView {
      min-width: 0;
      max-width: inherit;
      margin: 1rem;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
      width: calc(50% - 3rem); }
      .myPrinterCardView:nth-child(odd) {
        margin-left: 2rem; }
      .myPrinterCardView:nth-child(even) {
        margin-right: 2rem; } }
  @media screen and (min-width: 64em) {
    .myPrinterCardView {
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
      width: calc(33.333% - 2rem); }
      .myPrinterCardView:nth-child(odd) {
        margin-left: 1rem; }
      .myPrinterCardView:nth-child(even) {
        margin-right: 1rem; }
      .myPrinterCardView:nth-child(3n+1) {
        margin-left: 2rem;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
        width: calc(33.333% - 3rem); }
      .myPrinterCardView:nth-child(3n+3) {
        margin-right: 2rem;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
        width: calc(33.333% - 3rem); } }

.myPrinterDetails--meta {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin: 0 0.9375rem;
  padding-top: 0.625rem;
  border-top: 1px solid #DDD;
  font-size: 1rem; }

.myPrinterCardView--eventReceptor {
  display: fixed;
  position: absolute;
  background-color: rgba(255, 255, 0, 0);
  width: 98vw;
  z-index: 2;
  height: 75vh;
  max-height: 350px;
  overflow-x: hidden; }
  @media screen and (min-width: 40em) {
    .myPrinterCardView--eventReceptor {
      display: none; } }

.guardrail {
  display: fixed;
  position: absolute;
  background-color: rgba(255, 255, 0, 0);
  top: 175px;
  width: 18vw;
  z-index: 1;
  height: 75vh;
  max-height: 480px;
  overflow-x: hidden; }

#guardrail_right {
  right: 0; }

.myPrinterCardView--slider {
  overflow: hidden;
  overflow-x: hidden;
  width: 100vw;
  background-color: white; }

.myPrinterCardView--slides {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }

.myPrinterCardView--listSlides {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.myPrinterCardView--slide {
  margin: 0.25rem; }

.myPrinterCardView-blank {
  width: 70vw;
  max-width: 23.75rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 1.25rem auto 2.5rem;
  padding: 1.25rem;
  background-color: white; }

.myPrinterCardView .button {
  margin-bottom: 0;
  width: 100%; }

.myPrinterCardView--status {
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 1rem; }

.myPrinterCardView--ePrintSettingsTitle {
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  text-align: center; }

.myPrinterCardView--ePrintSettingsSummary {
  margin-bottom: auto; }

.myPrinterCardView--title-unRegistered {
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  text-align: center;
  color: #cc4b37; }

.myPrinterCardView--description {
  margin: auto 0; }
  @media print, screen and (min-width: 40em) {
    .myPrinterCardView--description {
      font-size: 1.25rem; } }

.myPrinterListView {
  border-top: 1px solid #979797;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
  margin: 0;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: start;
      justify-content: flex-start;
  min-height: 1.25rem;
  padding: 0.5rem 1.25rem;
  -ms-flex-align: center;
      align-items: center;
  color: #5A5A5A; }
  .myPrinterListView-unRegistered .myPrinterListView--title, .myPrinterListView-unRegistered .myPrinterListView--subtitle {
    color: #cc4b37; }
  .myPrinterListView:last-child {
    border-bottom: 1px solid #979797; }
  @media print, screen and (min-width: 40em) {
    .myPrinterListView {
      padding-left: 1.875rem; } }
  .myPrinterListView h3 {
    font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    margin-bottom: 0; }
  .myPrinterListView .myPrinter--icon {
    min-width: 1.1875rem; }
  .myPrinterListView:hover, .myPrinterListView:focus {
    text-decoration: none;
    color: #5A5A5A;
    background-color: rgba(242, 242, 242, 0.8); }

.myPrinterListView--description {
  margin-left: 1.25rem;
  overflow-wrap: break-word;
  overflow: hidden; }

.myPrinterListView--status {
  margin-left: auto;
  min-width: 1.5rem;
  text-align: center;
  padding-left: 0.5rem; }

.myPrinterDetails {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media print, screen and (min-width: 40em) {
    .myPrinterDetails {
      max-width: 39.9375rem;
      margin-right: auto;
      margin-left: auto;
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      min-width: initial; } }
  @media print, screen and (min-width: 64em) {
    .myPrinterDetails {
      max-width: 80rem; } }

h2.myPrinterDetails--unRegisteredTitle {
  font-size: 1.5rem;
  color: #cc4b37;
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  text-align: center; }

.myPrinterDetails--disconnectedGraphic {
  max-width: 9.375rem;
  margin: 1.25rem auto; }

.myPrinterDetails--directionsTitle {
  font-size: 1.25rem;
  text-align: center;
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }
  @media print, screen and (min-width: 40em) {
    .myPrinterDetails--directionsTitle {
      font-size: 1.75rem; } }

.myPrinterDetails--unRegistered {
  padding: 0 1.25rem;
  margin-bottom: 3.125rem;
  max-width: 35rem; }

.myPrinterSection--title {
  text-align: center;
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  margin: 0 auto 2.5rem;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.375rem;
  background-color: white;
  padding: 0.3125rem 0.3125rem 0;
  text-decoration: none; }
  @media print, screen and (min-width: 40em) {
    .myPrinterSection--title {
      font-size: 1.9375rem; } }

@media print, screen and (min-width: 40em) {
  .myPrinterDetails--directionsText {
    text-align: center; } }

.myPrinterDetails--directionsBlockLast {
  text-align: center;
  margin-top: 1.25rem; }

.myPrinterDetails--directionIcon {
  width: 3rem;
  margin: 1.25rem auto; }
  @media print, screen and (min-width: 40em) {
    .myPrinterDetails--directionIcon {
      width: 3.75rem;
      margin: 2.5rem auto; } }

.myPrinter--disconnected {
  width: 15.625rem;
  margin: 1.25rem auto; }

.myPrinterDetails--button {
  margin: 0 auto; }
  @media print, screen and (min-width: 40em) {
    .myPrinterDetails--button {
      margin: 1.25rem auto; } }

.accordionTitle {
  display: -ms-flexbox;
  display: flex;
  padding: 0.9375rem; }

a.accordionTitle .accordionTitle--title {
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  color: #5A5A5A;
  font-weight: bold; }

a.accordionTitle:hover {
  text-decoration: none; }

.setting--title, .promoSection--title h4 {
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1.25rem; }

.accordionTitle--status {
  margin-left: auto;
  color: #5A5A5A; }
  .ePrintSettingsAddress .accordionTitle--status {
    display: none; }
    @media screen and (min-width: 31.25rem) {
      .ePrintSettingsAddress .accordionTitle--status {
        display: block; } }

.accordionTitle--status-loading > img {
  width: 15px;
  height: 15px;
  display: inline-block; }

.accordionTitle--status-checked {
  line-height: 1; }

.myPrinterSection {
  padding: 2rem 0 3rem; }
  .myPrinterSection--ink {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto; }
  .myPrinterSection .settingsLoading--active {
    display: block;
    margin: 0 auto; }
  .myPrinterSection .accordion {
    display: block; }
  .myPrinterSection .printerGrid--printerCell {
    width: 100%; }
    @media print, screen and (min-width: 64em) {
      .myPrinterSection .printerGrid--printerCell {
        max-width: 50%; } }
  .myPrinterSection .printerCell--settings, .myPrinterSection .printerCell--promotion {
    display: none; }
  @media print, screen and (min-width: 64em) {
    .myPrinterSection {
      display: -ms-flexbox;
      display: flex;
      padding: 3rem 0 4rem;
      -ms-flex-pack: center;
          justify-content: center;
      border-bottom: 1px solid #B9B8BB; }
      .myPrinterSection .myPrinterSection--title {
        display: none; }
      .myPrinterSection .accordion {
        display: none; }
      .myPrinterSection .printerCell--settings, .myPrinterSection .printerCell--promotion {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-pack: justify;
            justify-content: space-between;
        padding: 0 1.25rem; } }

.accordionContentCarePack {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column; }

.promoSection--title {
  -ms-flex-pack: center;
      justify-content: center;
  padding-left: 1.4375rem;
  display: -ms-flexbox;
  display: flex; }
  @media print, screen and (min-width: 64em) {
    .promoSection--title {
      padding-left: 0; } }
  .promoSection--title h4 {
    margin-bottom: 0; }

.promoSection--animatedIcon {
  margin-left: 0;
  padding-left: 0.5rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.promoSection--contents {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-direction: column;
      flex-direction: column;
  text-align: center;
  color: black; }
  @media print, screen and (min-width: 64em) {
    .promoSection--contents {
      text-align: left;
      border: 1px solid #DDD;
      padding: 1rem 1rem 2rem; } }

@media print, screen and (min-width: 64em) {
  .noWarrantySubhead .promoSection--contents {
    margin-top: 2.5rem; } }

@media print, screen and (min-width: 64em) {
  .promoSection--ink {
    margin-top: 2.5rem;
    -ms-flex-pack: justify;
        justify-content: space-between; } }

.promoSection--subTitle {
  font-size: 1.25rem;
  text-align: center;
  padding-bottom: 1.25rem; }

.promoSection--warrantyRemaining {
  display: none;
  font-size: 0.875rem;
  text-align: center;
  margin-bottom: 0.875rem;
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }
  @media print, screen and (min-width: 64em) {
    .promoSection--warrantyRemaining {
      display: visible; } }
  @media print, screen and (min-width: 40em) {
    .promoSection--warrantyRemaining {
      font-size: 1rem;
      margin-bottom: 1rem; } }

.promoSection--details {
  margin-bottom: 1.25rem; }

.serviceNameBelow, .serviceNameBelow p {
  margin-bottom: 0; }

.promoSection--details, .promoSection--details > * {
  text-align: center; }

.promoSection--serviceName {
  text-align: center;
  margin-bottom: 1rem;
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }

.promoSection--contactInfo > * {
  text-align: center; }

.promoSection--availability, .promoSection--availability > * {
  text-align: center;
  font-size: 0.875rem;
  margin-bottom: 0.875rem;
  color: #5a5a5a; }

.promoSection--modelSerial {
  text-align: center;
  font-size: 0.875rem;
  margin-bottom: 0.875rem;
  color: #5a5a5a; }

button.hollow.promoSection--button {
  margin: 0 auto;
  width: auto;
  border: solid 2px #0096d6;
  -ms-flex-item-align: center;
      align-self: center; }

@keyframes scale {
  0% {
    transform: scale3d(0.5, 0.5, 1);
    opacity: 0; }
  66% {
    transform: scale3d(0.5, 0.5, 1);
    opacity: 0; }
  90% {
    transform: scale3d(1.1, 1.1, 1);
    opacity: 1; }
  100% {
    transform: scale3d(1, 1, 1); } }

.checkmark {
  transform: scale3d(0.5, 0.5, 1);
  animation: scale 3s ease-in-out forwards; }

.exclamationIcon {
  transform: scale3d(0.5, 0.5, 1);
  animation: scale 3s ease-in-out forwards; }

.exclamationMark--mark {
  stroke: #FFF;
  stroke-width: 3.5;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.75s cubic-bezier(0.65, 0, 0.45, 1) 3s forwards; }

@keyframes stroke {
  100% {
    stroke-dashoffset: 0; } }

.checkmark--check {
  stroke: #FFF;
  stroke-width: 3;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.75s cubic-bezier(0.65, 0, 0.45, 1) 3s forwards; }

.inkLevels {
  margin: 0.5rem 1.25rem 1.25rem;
  text-align: center;
  display: -ms-flexbox;
  display: flex; }
  .inkLevels > * + * {
    margin-left: 0.4375rem; }

.inkLevels--meter > * {
  height: 6.25rem; }

.inkLevels--disclaimer {
  margin: 0 auto 1.25rem;
  text-align: center;
  font-size: 1rem; }

.reveal > .remove-printer-description {
  margin-bottom: 1rem; }

.thumbNav {
  margin: 0 auto;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
  max-width: 65%;
  height: 1rem;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-line-pack: distribute;
      align-content: space-around;
  padding-bottom: 50px; }

button.thumbNav--dot-inactive {
  border-radius: 0;
  animation: scale 1s;
  display: inline-block;
  background: #e6e6e6;
  transition-property: width, height;
  height: 1.125rem;
  width: 0.75rem;
  margin: 0.25rem 0.375rem; }
  button.thumbNav--dot-inactive:hover {
    background: #b5b4b7; }
  button.thumbNav--dot-inactive:focus {
    outline: none; }

button.thumbNav--dot-active {
  border-radius: 0;
  animation: scale 1s;
  display: inline-block;
  background: #b5b4b7;
  transition: 0.3s;
  height: 1.5rem;
  width: 1rem;
  margin: 0 0.25rem; }
  button.thumbNav--dot-active:focus {
    outline: none; }

.myPrintersloggedOut {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
  position: relative; }

.myPrintersLoggedOut--background {
  z-index: -50;
  margin-left: -0.625rem;
  margin-right: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .myPrintersLoggedOut--background {
      margin-left: 0;
      margin-right: 0; } }

.myPrintersloggedOut--text {
  position: absolute;
  top: 42%;
  margin-left: 0;
  margin-right: 0;
  left: 0;
  right: 0; }

.myPrintersloggedOut--textLinks {
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1.25rem; }
  @media print, screen and (min-width: 40em) {
    .myPrintersloggedOut--textLinks {
      font-size: 2rem; } }
  .myPrintersloggedOut--textLinks a {
    color: black;
    border-bottom: #0096d6 solid 0.3125rem; }
    .myPrintersloggedOut--textLinks a:hover, .myPrintersloggedOut--textLinks a:active {
      color: #0096d6;
      text-decoration: none; }

.myPrintersloggedOut--subhead {
  text-align: center;
  color: black;
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  margin-bottom: 5rem;
  padding: 0 5%;
  text-shadow: white 0 0 10px; }
  @media print, screen and (min-width: 40em) {
    .myPrintersloggedOut--subhead {
      font-size: 1.5rem; } }

.addPrinter--buttonPair {
  display: -ms-flexbox;
  display: flex; }
  .addPrinter--buttonPair a {
    width: 50%;
    font-size: 1.125rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1.25rem; }
  .addPrinter--buttonPair * + * {
    margin-left: 1.25rem; }
  .addPrinter--buttonPair a:last-child {
    border: solid 2px; }

.addPrinter--intro, .addPrinter--fieldset, .addPrinter--directions {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .addPrinter--intro, .addPrinter--fieldset, .addPrinter--directions {
      width: 100%;
      padding-left: 1.25rem;
      padding-right: 1.25rem; } }
  @media screen and (min-width: 52rem) {
    .addPrinter--intro, .addPrinter--fieldset, .addPrinter--directions {
      width: 52rem;
      margin-left: auto;
      margin-right: auto; } }

.addPrinter--form {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
  flex: 1 1 auto;
  margin-bottom: 3.125rem; }

.addPrinter--intro p:first-child {
  font-size: 1.25rem; }

.addPrinter--fieldLable {
  font-size: 2rem;
  margin-bottom: 1.25rem; }

.addPrinterButton {
  padding: 0.625rem;
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }

.addPrinterButton--icon {
  display: none; }

.addPrinter--directions {
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }

.addPrinter--claimCodeExamples {
  display: -ms-flexbox;
  display: flex; }

.addPrinter--claimCodeThumb:first-child {
  margin-right: 10px; }

.addPrinter--claimCodeThumb:last-child {
  margin-left: 10px; }

.addPrinter--claimCodeThumb img {
  width: 100%;
  height: auto; }

.myPrinterSetting {
  border: 1px solid #DDD;
  margin-bottom: 1rem; }

.printerGrid--printerCell:last-child .myPrinterSetting {
  margin-bottom: 0; }

.ePrintSettings--textLink {
  display: block;
  text-align: center;
  margin-top: 0.3125rem;
  margin-bottom: 0.625rem;
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }

@media print, screen and (min-width: 64em) {
  .myPrinterSection--ePrintSettings .accessOptions {
    border-left: 1px solid #DDD;
    border-right: 1px solid #DDD; } }

.myPrinterSection--ePrintSettings .ePrintSection--access {
  -ms-flex-order: 1;
      order: 1; }
  @media print, screen and (min-width: 64em) {
    .myPrinterSection--ePrintSettings .ePrintSection--access .ePrintAccessSetting-current {
      background-color: transparent;
      padding: 1.25rem; } }
  .myPrinterSection--ePrintSettings .ePrintSection--access .accessOptions--button-current {
    background-color: transparent; }
  .myPrinterSection--ePrintSettings .ePrintSection--access .ePrintAccessSetting {
    background-color: #e6e6e6;
    border-top: 1px solid #DDD;
    padding: 1.25rem;
    border-right: 1px solid #DDD;
    border-bottom: 1px solid #DDD;
    border-left: 1px solid #DDD; }
    @media print, screen and (min-width: 64em) {
      .myPrinterSection--ePrintSettings .ePrintSection--access .ePrintAccessSetting {
        background-color: transparent; } }
    .myPrinterSection--ePrintSettings .ePrintSection--access .ePrintAccessSetting p {
      color: black; }

.myPrinterSection--ePrintSettings .ePrintSection--address {
  -ms-flex-order: 2;
      order: 2; }
  .myPrinterSection--ePrintSettings .ePrintSection--address:last-child {
    -ms-flex-order: 0;
        order: 0; }
  @media print, screen and (min-width: 64em) {
    .myPrinterSection--ePrintSettings .ePrintSection--address .ePrintAddressChange {
      background-color: transparent; } }

.myPrinterSection--ePrintSettings .ePrintSection--preferences {
  -ms-flex-order: 3;
      order: 3; }

@media print, screen and (min-width: 64em) {
  .printerCell--settings .ePrintAddressChange {
    padding: 0 1.25rem; } }

.ePrintSettingsAddress .accordionTitle--status {
  text-align: right;
  word-break: break-all; }

.ePrintAddressChange {
  background-color: #e6e6e6;
  border-top: 1px solid #DDD;
  padding: 1.25rem; }
  @media print, screen and (min-width: 64em) {
    .ePrintAddressChange {
      background-color: transparent; } }
  .ePrintAddressChange p {
    color: black; }
  .ePrintAddressChange--buttonPair {
    display: -ms-flexbox;
    display: flex; }
    .ePrintAddressChange--buttonPair a {
      width: 50%;
      font-size: 1rem;
      padding-left: 1em;
      padding-right: 1em;
      margin-bottom: 1.25rem; }
    .ePrintAddressChange--buttonPair * + * {
      margin-left: 1.25rem; }
    .ePrintAddressChange--buttonPair a:last-child {
      border: solid 2px; }
  @media print, screen and (min-width: 64em) {
    .ePrintAddressChange {
      border-top: none; }
      .ePrintAddressChange .setting--title {
        display: none; } }

.ePrintAddressChange--addressLabel {
  text-align: center;
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  line-height: 1;
  padding-bottom: 0.25rem; }

.ePrintAddressChange--address {
  word-break: break-all;
  text-align: center;
  margin-bottom: 1.25rem; }

.ePrintAddressChange--inputArea {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 1.25rem; }
  .ePrintAddressChange--inputArea input {
    margin-bottom: 0;
    margin-right: 0.625rem; }

.ePrintAddressChange--inputArea-rtl input {
  margin-bottom: 0;
  margin-right: 0;
  margin-left: 0.625rem; }

.ePrintAddressChange--domain {
  font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  word-break: break-all;
  font-size: 1.125rem; }

.ePrintAccessChange {
  display: block; }
  .ePrintAccessChange .setting--title {
    display: block; }
    @media print, screen and (min-width: 64em) {
      .ePrintAccessChange .setting--title {
        display: none; } }

.ePrintAccessDetails {
  padding: 1.25rem;
  background-color: #F2F2F2;
  display: none; }
  .ePrintAccessDetails-open {
    display: block; }

.accessOptions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  border-top: 1px solid #DDD; }

.accessOptions--button {
  width: 100%;
  min-height: 0.625rem;
  padding: 0.875rem 0.3125rem 0.875rem 1.5rem;
  font-size: 0.875rem;
  border-bottom: 1px solid #DDD;
  border-radius: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: start;
      justify-content: flex-start;
  color: #5a5a5a; }
  @media print, screen and (min-width: 64em) {
    .accessOptions--button {
      padding-left: 1rem; } }
  .accessOptions--button:last-child {
    border-bottom: none; }
  .accessOptions--button:focus {
    outline: none; }
  .accessOptions--button::before {
    content: url(/assets/img/common/icon-radio-button-off.svg);
    margin-right: 0.5rem; }
  .accessOptions--button:hover, .accessOptions--button:active {
    background-color: #F0F0F0; }
  .accessOptions--button-current {
    background-color: #F7F7F7; }
    @media print, screen and (min-width: 64em) {
      .accessOptions--button-current {
        font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; } }
    .accessOptions--button-current::before {
      content: url(/assets/img/common/icon-radio-button-on.svg);
      margin-right: 0.5rem; }
  .accessOptions--button-inactive::before {
    content: url(/assets/img/common/icon-radio-button-off.svg);
    margin-right: 0.5rem; }
  .accessOptions--button-inactive:hover, .accessOptions--button-inactive:active {
    background-color: #FFF; }
  .accessOptions--button-rtl::before {
    margin-left: 0.5rem;
    margin-right: 0px !important; }
  @media print, screen and (min-width: 64em) {
    .accessOptions--button:not(.accessOptions--button-current) {
      color: #0096d6; } }

.ePrintAccessSetting {
  border-top: 1px solid #DDD;
  background-color: #e6e6e6;
  padding: 1.25rem 0.9375rem; }
  .ePrintAccessSetting p {
    color: black; }
  .ePrintAccessSetting-current {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column; }

.ePrintAccessSetting--explanation {
  color: black; }

.ePrintAccessSetting--inputs {
  display: -ms-flexbox;
  display: flex; }

.ePrintAccessSetting--button {
  padding: 0.5rem 0.75rem;
  margin-left: 0.9375rem; }

.ePrintAccessSetting--addresses {
  margin: 0 0 0.375rem; }
  .ePrintAccessSetting--addresses li {
    list-style-type: none; }

.ePrintAccessSetting--email {
  word-break: break-all; }

.ePrintAccessSetting--address {
  padding-left: 0.125rem; }
  .ePrintAccessSetting--address-removable button {
    color: #0096d6;
    padding: 0.3125rem 0.625rem;
    margin-left: -0.625rem; }
    .ePrintAccessSetting--address-removable button:hover, .ePrintAccessSetting--address-removable button:active {
      color: #cc4b37; }

.ePrintAccessSetting--icon-owner {
  padding: 0.3125rem 0.625rem;
  margin-left: -0.625rem;
  color: #767676; }

.ePrintAccessSetting--id-owner {
  color: black;
  font-style: italic; }

.ePrintAccessSetting--checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ePrintAccessSetting--checkbox input[type='checkbox'] {
    margin: 0 0.375rem 0.25rem 0; }
  .ePrintAccessSetting--checkbox .ePrintAccessSetting--checkboxLabel {
    color: black; }

.rtl-checkbox {
  margin-left: 0.375rem !important; }

@media print, screen and (min-width: 64em) {
  .ePrintSettingsPreferences {
    padding: 1.25rem; } }

.ePrintPreference {
  padding: 0.625rem 0.9375rem 0.625rem 2.5rem;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #DDD;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-line-pack: justify;
      align-content: space-between; }

.ePrintPreference-summary {
  border-top: 1px solid #DDD;
  -ms-flex-direction: row;
      flex-direction: row; }
  @media print, screen and (min-width: 64em) {
    .ePrintPreference-summary {
      display: none; } }

.ePrintPreference--value {
  margin-left: auto; }

.ePrintPreference--optionSet {
  max-height: 0;
  overflow: hidden;
  background-color: #e6e6e6;
  border: none;
  padding: 0; }
  @media print, screen and (min-width: 64em) {
    .ePrintPreference--optionSet {
      max-height: inherit; } }
  @media print, screen and (min-width: 64em) {
    .ePrintPreference--optionSet {
      background-color: transparent; } }

@media print, screen and (min-width: 64em) {
  .preferenceContentContainer {
    border: 1px solid #DDD;
    border-bottom: none; }
    .preferenceContentContainer:last-of-type {
      border-bottom: 1px solid #DDD; } }

.ePrintPreference--name {
  margin-bottom: 0;
  margin-right: auto; }

.ePrintPreference--form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row; }

.ePrintPreference--option {
  min-height: 0.625rem;
  font-size: 0.875rem;
  border-radius: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: start;
      justify-content: flex-start;
  color: #5a5a5a;
  padding: 0.75rem 0.625rem 0.75rem 2.5rem;
  width: 50%;
  background-color: #d6d6d6; }
  @media print, screen and (min-width: 64em) {
    .ePrintPreference--option {
      background-color: #F9F9F9;
      color: #0096d6;
      border-bottom: 1px solid #DDD;
      padding-left: 1rem; } }
  .ePrintPreference--option:first-child {
    border-right: 1px solid #DDD; }
  .ePrintPreference--option:hover, .ePrintPreference--option:active {
    background-color: #e0e0e0; }
  .ePrintPreference--option::before {
    content: url(/assets/img/common/icon-radio-button-off.svg);
    margin-right: 0.5rem; }
  .ePrintPreference--option:focus {
    outline: none; }
  .ePrintPreference--option-active {
    background-color: #e6e6e6; }
    @media print, screen and (min-width: 64em) {
      .ePrintPreference--option-active {
        background-color: transparent;
        border-bottom: none;
        color: #5a5a5a;
        font-family: "hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; } }
    .ePrintPreference--option-active::before {
      content: url(/assets/img/common/icon-radio-button-on.svg);
      margin-right: 0.5rem; }
    .ePrintPreference--option-active:hover, .ePrintPreference--option-active:active {
      background-color: #e6e6e6; }
      @media print, screen and (min-width: 64em) {
        .ePrintPreference--option-active:hover, .ePrintPreference--option-active:active {
          background-color: transparent; } }
  .ePrintPreference--option-inactive {
    background-color: #e6e6e6; }
    @media print, screen and (min-width: 64em) {
      .ePrintPreference--option-inactive {
        background-color: #F9F9F9; } }
    .ePrintPreference--option-inactive::before {
      content: url(/assets/img/common/icon-radio-button-off.svg);
      margin-right: 0.5rem; }
    .ePrintPreference--option-inactive:hover, .ePrintPreference--option-inactive:active {
      background-color: #e6e6e6; }
      @media print, screen and (min-width: 64em) {
        .ePrintPreference--option-inactive:hover, .ePrintPreference--option-inactive:active {
          background-color: #F9F9F9; } }

.rtl-button {
  margin-left: 0 !important;
  margin-right: 0.5rem; }

.ePrintPreference--option-rtl::before {
  margin-left: 0.5rem;
  margin-right: 0px !important; }

.ePrintPreference--option-rtl:first-child {
  border-right: none !important;
  border-left: 1px solid #DDD; }

.ePrintPreference--explanation {
  padding: 0.9375rem;
  display: inline-block; }

@media print, screen and (min-width: 64em) {
  .myPrinterSection--socialWidget {
    -ms-flex-direction: column;
        flex-direction: column; }
    .myPrinterSection--socialWidget .myPrinterSection--title {
      display: block;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
      margin-bottom: 0; } }

.socialWidget {
  border: 1px solid #DDD; }
  @media print, screen and (min-width: 64em) {
    .socialWidget {
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
      border: none; } }

.fb-send-to-messenger, .fb_iframe_widget {
  width: 11.25rem !important;
  margin: 2.5rem auto 1.25rem !important;
  display: block !important; }

.socialWidget--description {
  padding: 0.9375rem;
  text-align: center; }

.simplePage {
  padding: 0 1.25rem; }

.simplePage--primaryHeading {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 2.25rem; }

.simplePage--date {
  text-align: center;
  margin-bottom: 2.25rem;
  font-family: 'hp-simplified', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif; }
  @media screen and (min-width: 40em) {
    .simplePage--date {
      font-size: 1.25rem; } }

.simplePage--mainContent {
  max-width: 37.5rem;
  margin: auto;
  margin-bottom: 5rem; }
  @media screen and (min-width: 40em) {
    .simplePage--mainContent {
      font-size: 1.25rem; } }

@media print {
  .simplePage--mainContent {
    max-width: inherit;
    padding: .5in; }
  .gnb--bg, .footer--bg {
    display: none; } }

@font-face {
  font-family: "hp-simplified-bold";
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-bold-eot.eot");
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-bold-eot.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-bold-woff.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "hp-simplified-bold-italic";
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-boldItalic-eot.eot");
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-boldItalic-eot.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-boldItalic-woff.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "hp-simplified-italic";
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-italic-eot.eot");
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-italic-eot.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-italic-woff.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "hp-simplified-light";
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-light-eot.eot");
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-light-eot.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-light-woff.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "hp-simplified-light-italic";
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-lightItalic-eot.eot");
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-lightItalic-eot.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-lightItalic-woff.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "hp-simplified-regular";
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-regular-eot.eot");
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-regular-eot.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-regular-woff.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "hp-simplified";
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-regular-eot.eot");
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-regular-eot.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-regular-woff.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "hp-seven";
  src: url("/assets/fonts/no-seven-regular-eot.eot");
  src: url("/assets/fonts/no-seven-regular-eot.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/no-seven-regular-woff.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "HPSimplifiedBold";
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-bold-eot.eot");
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-bold-eot.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-bold-woff.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "HPSimplifiedBdItalic";
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-boldItalic-eot.eot");
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-boldItalic-eot.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-boldItalic-woff.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "HPSimplifiedItalic";
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-italic-eot.eot");
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-italic-eot.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-italic-woff.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "HPSimplifiedLight";
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-light-eot.eot");
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-light-eot.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-light-woff.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "HPSimplifiedLtItalic";
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-lightItalic-eot.eot");
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-lightItalic-eot.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-lightItalic-woff.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "HPSimplifiedRegular";
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-regular-eot.eot");
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-regular-eot.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-regular-woff.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "HPSimplified";
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-regular-eot.eot");
  src: url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-regular-eot.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/latin-greek-cyrillic/latinGreekCyrillic-regular-woff.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "HPSeven";
  src: url("/assets/fonts/no-seven-regular-eot.eot");
  src: url("/assets/fonts/no-seven-regular-eot.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/no-seven-regular-woff.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Forma DJR';
  font-style: normal;
  font-weight: normal;
  src: url(./../fonts/5f5ffa5f043b7d10c6816f020a5e105a.ttf) format("truetype"); }

#view + .css-gakx9k-SelectPopover {
  box-shadow: 0px 8px 24px rgba(33, 33, 33, 0.1); }

@media screen and (max-width: 767px) {
  .continuebutton {
    width: 100%;
    display: block;
    text-align: center; } }

@media screen and (max-width: 767px) {
  .backbutton {
    display: none; } }
