@font-face {
  font-family: 'Forma DJR';
  font-style: normal;
  font-weight: normal;
  src: url(./../fonts/5f5ffa5f043b7d10c6816f020a5e105a.ttf) format("truetype"); }

@font-face {
  font-family: 'Forma DJR Italic';
  font-weight: normal;
  font-style: normal;
  src: url(./../fonts/eeca5f529d4f663e02b2b78f6862a0f6.ttf) format("opentype"); }
